import { useBillingApi } from '@/composables/cloudApi'
import type {
  V1alpha1SubscriptionIntent,
  V1alpha1PublicOfferList,
  V1alpha1PublicOffer,
  V1alpha1SetupIntent,
  V1alpha1PaymentIntent,
  V1alpha1SubscriptionIntentList,
  CloudV1alpha1Organization,
  V1alpha1SugerSubscriptionIntentSpec,
  V1alpha1SugerEntitlementReview,
  V1alpha1Subscription
} from '@streamnative/cloud-api-client-typescript/api'
import type { Ref } from 'vue'
import type { PulsarState } from '@/composables/usePulsarState'
import { pricePackages, type PricePackageKeys } from '@/data/pricePackages'

const billingApiVersion = 'billing.streamnative.io/v1alpha1'
const streamnativeNamespace = 'streamnative'
const consumptionProductName = 'SN2_CONSUMPTION_UNIT'
const metadataPricePackageKey = 'price_package_name'
const discountExhaustedDateAnnotation =
  'annotations.cloud.streamnative.io/free-credit-exhausted-sent-at'
const ignoreExpireAnnotation = 'annotations.cloud.streamnative.io/ignore-expire'
const getPublicOffers = async (): Promise<V1alpha1PublicOfferList> => {
  try {
    const api = useBillingApi()
    const { data } = await api.listPublicOffer()
    return data
  } catch (e) {
    throw new Error(getErrorMessage(e))
  }
}

const getHostedPublicOffer = async (): Promise<V1alpha1PublicOffer | undefined> => {
  const offers = await getPublicOffers()
  // TODO name is subject to change
  return offers.items.find(o => {
    return o.metadata?.name === 'SN2_OD_HOSTED_CLOUD'
  })
}

const createSubscriptionIntent = async (
  organization: string,
  suger?: V1alpha1SugerSubscriptionIntentSpec
): Promise<V1alpha1SubscriptionIntent> => {
  const api = useBillingApi()
  const hostedOffer = await getHostedPublicOffer()
  if (!hostedOffer) {
    throw new Error(
      'Hosted Offer is undefined, unable to setup subscription. Please contact technical support.'
    )
  }

  let spec = {}
  if (suger) {
    spec = {
      suger,
      type: 'suger'
    }
  } else {
    spec = {
      offerRef: {
        kind: 'PublicOffer',
        // metadata name should never be blank, this is a quirk of the autogenerated client
        name: hostedOffer.metadata?.name || ''
      },
      type: 'stripe'
    }
  }

  const subscriptionIntent: V1alpha1SubscriptionIntent = {
    apiVersion: billingApiVersion,
    kind: 'SubscriptionIntent',
    metadata: {
      generateName: 'subscription-',
      namespace: organization
    },
    spec: {
      // TODO this should change with the introduction of private offers.
      //      the user should select the offer associated with their org
      //      and this should be updated appropriately
      ...spec
    }
  }
  try {
    const { data } = await api.createNamespacedSubscriptionIntent(organization, subscriptionIntent)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e))
  }
}

const getSubscriptionIntent = async (
  name: string,
  org: string
): Promise<V1alpha1SubscriptionIntent> => {
  const api = useBillingApi()
  try {
    const { data } = await api.readNamespacedSubscriptionIntent(name, org)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e))
  }
}

const listSubscriptionIntent = async (org: string): Promise<V1alpha1SubscriptionIntentList> => {
  const api = useBillingApi()
  try {
    const { data } = await api.listNamespacedSubscriptionIntent(org)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e))
  }
}

const createSugerEntitlementReview = async (
  entitlementID: string
): Promise<V1alpha1SugerEntitlementReview> => {
  const api = useBillingApi()

  const sugerEntitlementReview: V1alpha1SugerEntitlementReview = {
    apiVersion: billingApiVersion,
    kind: 'SugerEntitlementReview',
    spec: {
      entitlementID
    }
  }
  try {
    const { data } = await api.createSugerEntitlementReview(sugerEntitlementReview)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e))
  }
}

const getPaymentIntent = async (org: string, name: string): Promise<V1alpha1PaymentIntent> => {
  const api = useBillingApi()
  try {
    const { data } = await api.readNamespacedPaymentIntent(name, org)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e))
  }
}

const getSetupIntent = async (org: string, name: string): Promise<V1alpha1SetupIntent> => {
  const api = useBillingApi()
  try {
    const { data } = await api.readNamespacedSetupIntent(name, org)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e))
  }
}

const getStripeIntentFromSubscriptionIntent = async (
  org: string
): Promise<V1alpha1PaymentIntent | V1alpha1SetupIntent> => {
  const activeSubscription = await findActiveSubscription(org)
  if (!activeSubscription?.metadata?.name) {
    throw Error('subscription name is not found')
  }
  const subscriptionName = activeSubscription.metadata?.name
  const sis = await listSubscriptionIntent(org)
  const si = sis.items.find(si => si.status?.subscriptionName === subscriptionName)
  if (!si) {
    throw Error('not found')
  }
  const setupIntent = si.status?.setupIntentName
  const paymentIntent = si.status?.paymentIntentName

  let intent = undefined
  if (setupIntent) {
    intent = await getSetupIntent(org, setupIntent)
    return intent
  }

  if (paymentIntent) {
    intent = await getPaymentIntent(org, paymentIntent)
    return intent
  }

  throw new Error('SubscriptionIntent is not ready.')
}

const findActiveSubscription = async (orgName: string) => {
  const api = useBillingApi()
  return (await api.listNamespacedSubscription(orgName)).data.items.find(i => {
    if (i.status?.conditions?.find(c => c.type === 'Canceled' && c.status === 'True')) {
      return false
    }
    if (i.status?.conditions?.find(c => c.type === 'Active' && c.status === 'False')) {
      return false
    }
    return true
  })
}

const getSubscription = async ({
  organization,
  subscription
}: {
  organization: string
  subscription: string
}): Promise<V1alpha1Subscription> => {
  const api = useBillingApi()
  return (await api.readNamespacedSubscription(subscription, organization)).data
}

// if undefined is returned, there is an active subscription and no need to create
// else return name of the subscription to wait on
export const checkAndCreateSubscriptionIntent = async (
  organization: CloudV1alpha1Organization
): Promise<V1alpha1SubscriptionIntent | undefined> => {
  if (!organization.metadata?.name) {
    throw Error('organization name is missing')
  }
  const activeSub = await findActiveSubscription(organization.metadata.name)

  // check for active subscription
  if (activeSub) {
    return
  }

  // check for pending subscription intents
  const api = useBillingApi()
  const sis = await api.listNamespacedSubscriptionIntent(organization.metadata.name)
  const processingSubsIntent = sis.data.items.find(i => {
    const succededStatus = i.status?.conditions?.find(c => c.type === 'Succeeded')
    const cancelledStatus = i.status?.conditions?.find(c => c.type === 'Cancelled')
    return (
      (!succededStatus || succededStatus.status === 'False') &&
      (!cancelledStatus || cancelledStatus.status === 'False')
    )
  })
  if (processingSubsIntent) {
    return processingSubsIntent
  }

  // create subscription intent
  return await createSubscriptionIntent(organization.metadata?.name)
}

const activeSubscription: Ref<V1alpha1Subscription | undefined> = ref(undefined)
let lastOrg: string | undefined = undefined

export const init = (initialState: PulsarState) => {
  const { organization } = usePulsarState()
  const { isRbacUpdating } = rbacHelper()

  const valueChanged = async ([org, ab]: [string | undefined, boolean | undefined]) => {
    if (!org) {
      activeSubscription.value = undefined
      lastOrg = undefined
      return
    }
    if (ab) {
      return
    }

    if (org !== lastOrg) {
      // TODO we should double check this vs the spec. 'Subsciription' may be
      //      the wrong type to use here. org-read-only can view 'Subscription'
      //      but that might be incorrect
      const { canDescribeBilling } = rbacManager()
      if (canDescribeBilling()) {
        activeSubscription.value = await findActiveSubscription(org)
      }
    }
    lastOrg = org
  }

  watch([organization, isRbacUpdating], valueChanged)

  return valueChanged([initialState.organization, isRbacUpdating.value])
}

const activePricePackageName = computed(() => {
  const defaultPricePackage: PricePackageKeys = '2024-pricing-package'

  if (!activeSubscription.value) {
    return defaultPricePackage
  }
  const consumptionProduct = (activeSubscription.value.spec?.recurring || []).find(
    item =>
      item.product?.namespace === streamnativeNamespace &&
      item.product?.name === consumptionProductName
  )

  if (!consumptionProduct || !consumptionProduct.metadata) {
    return defaultPricePackage
  }

  const pricePackageValue = consumptionProduct?.metadata[metadataPricePackageKey]
  const pricePackageRequested = pricePackages[pricePackageValue as PricePackageKeys]

  if (!pricePackageRequested) {
    return defaultPricePackage
  }
  return pricePackageValue as PricePackageKeys
})

const activePricePackage = computed(() => {
  return pricePackages[activePricePackageName.value]
})

const discountCreditRemaining = computed(() => {
  const { getAppliedDiscountValueCent } = useOrganization()
  return activeSubscription.value?.spec?.endingBalanceCents
    ? ((activeSubscription.value?.spec?.endingBalanceCents || 0) * -1) / 100
    : getAppliedDiscountValueCent.value / 100
})

const discountExhaustedDate = computed(() => {
  return parseInt(
    activeSubscription.value?.metadata?.annotations?.[discountExhaustedDateAnnotation] ?? '0'
  )
})

const subscriptionEndDate = computed(() => {
  return activeSubscription.value?.spec?.endDate?.split('T')[0]
})

const oneMonthMs = 1000 * 60 * 60 * 24 * 30
const isSubscriptionEndBannerDisplay = computed(() => {
  const isIgnore =
    activeSubscription.value?.metadata?.annotations?.[ignoreExpireAnnotation] === 'true'
  if (!subscriptionEndDate.value || isIgnore) {
    return false
  }

  const diff = Date.parse(subscriptionEndDate.value) - Date.now()
  if (!diff) {
    return false
  }

  return diff < oneMonthMs
})

const discountCreditRemainingPretty = computed(() => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(discountCreditRemaining.value)
})

const isPayGoSubscription = computed(() => {
  return activeSubscription.value?.spec?.description === 'Pay-Go Monthly Subscription'
})

export const useSubscription = () => {
  return {
    getPublicOffers,
    createSubscriptionIntent,
    getSubscriptionIntent,
    createSugerEntitlementReview,
    getSetupIntent,
    getStripeIntentFromSubscriptionIntent,
    listSubscriptionIntent,
    getSubscription,
    discountCreditRemaining,
    discountExhaustedDate,
    activeSubscription,
    activePricePackage,
    subscriptionEndDate,
    isSubscriptionEndBannerDisplay,
    discountCreditRemainingPretty,
    isPayGoSubscription,
    activePricePackageName,
    init
  }
}
