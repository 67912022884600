import type {
  CloudV1alpha1Organization,
  V1alpha1SelfSubjectUserReview,
  V1alpha1UserRef
} from '@streamnative/cloud-api-client-typescript'
import { fetchOrganization } from '@/api/organizations'
import { useAnalytics } from '@/composables/analytics'
import type { PulsarState } from './usePulsarState'
import type { LocationQueryValue } from 'vue-router'
import type {
  V1alpha1SelfRegistration,
  V1alpha1SelfRegistrationSpec
} from '@streamnative/cloud-api-client-typescript'
import { useCloudApi } from '@/composables/cloudApi'
import { i18n } from '@/lang'
import { createCustomerPortalRequest } from '@/api/stripe2'
import { clearCachedOrgMetadata, clearLastViewedOrgIns } from '@/utils/localStorageHelper'
import type { ComputedRef } from 'vue'
import { fetchSelfSubjectUserReview } from '@/api/rbac'
import { view, Organization } from '@/composables/useRbac'
import { getAdminsForTenants } from '@/api/tenants'

const { t } = i18n.global

let lastOrg: string | undefined = undefined

const annotationDiscountPrefix = 'annotations.cloud.streamnative.io/discount'

const activeOrganization = ref<CloudV1alpha1Organization | undefined>(undefined)
const selfSubjectUserReview = ref<V1alpha1SelfSubjectUserReview>({})
const selfSubjectUsers = computed<V1alpha1UserRef[]>(() => {
  return selfSubjectUserReview.value.status?.users ?? []
})
const billingType = computed<string | undefined>(() => activeOrganization.value?.spec?.billingType)
const displayName = computed<string | undefined>(() => activeOrganization.value?.spec?.displayName)
const organizationReady = computed<boolean>(
  () =>
    activeOrganization.value?.status?.conditions?.find(condition => condition.type === 'Ready')
      ?.status === 'True'
)
const organizationOptions = computed<{ id: string; name: string | undefined }[]>(() => {
  const namespaces = selfSubjectUserReview.value.status?.users.map(u => u.namespace)

  return Array.from(new Set(namespaces ?? []))
    .map(namespace => {
      return {
        id: namespace,
        name:
          selfSubjectUserReview.value.status?.users.find(u => u.namespace === namespace)
            ?.organization.displayName ?? undefined
      }
    })
    .sort((a, b) => a.id?.localeCompare(b.id) ?? 0)
})

const organizationHasValidSubscription = computed<boolean>(() => {
  if (activeOrganization.value?.spec?.type !== 'stripe') {
    return true
  }

  return (
    activeOrganization.value?.status?.conditions?.find(condition => condition.type === 'Subscribed')
      ?.status === 'True'
  )
})

const isSugerOrganization = computed(
  () => !!activeOrganization.value?.spec?.suger?.buyerIDs?.length
)

const getAppliedDiscountValueCent = computed(() => {
  const annotations = activeOrganization.value?.metadata?.annotations ?? {}
  const discountKeys = Object.keys(annotations).filter(k => k.startsWith(annotationDiscountPrefix))
  return discountKeys.map(k => parseInt(annotations[k])).reduce((pv, cv) => pv + cv, 0)
})

const sugerPartner: ComputedRef<boolean | string> = computed(() => {
  const activeSubscription = useSubscription().activeSubscription
  if (!activeSubscription.value?.spec?.suger) {
    return false
  }

  const partner = activeSubscription.value?.spec?.suger?.partner?.toLowerCase()
  if (partner) {
    return partner
  }

  return false
})

const organizationHasBillingMethod = computed<boolean>(() => {
  const hasPaymentMethodStatus =
    activeOrganization.value?.status?.conditions?.find(
      condition => condition.type === 'HasPaymentMethod'
    )?.status === 'True'

  if (hasPaymentMethodStatus) {
    return true
  }

  const discountActiveCond = activeOrganization.value?.status?.conditions?.find(
    condition => condition.type === 'DiscountActive'
  )

  if (discountActiveCond !== undefined && discountActiveCond.status !== 'Unknown') {
    // discount active condition is found and it is known, return result of comparison
    return discountActiveCond.status === 'True'
  }

  if (getAppliedDiscountValueCent.value) {
    // discount active condition at org is not set or unknown
    // it means either:
    // - subscription is not created (if subscription has been created before we
    //     would have retained discount active condition from the previous subscription)
    // - subscription is created but reporter hasn't run to fill ending balance
    return true
  }
  return false
})

const isActiveOrgMaintenanceWindowEditable = computed<boolean>(() => {
  if (!activeOrganization.value) {
    return false
  }
  const maintenanceWindowCond = activeOrganization.value.status?.conditions?.find(
    cond => cond.type === 'MaintenanceWindow'
  )
  return maintenanceWindowCond?.status === 'True'
})

const isValidOrganizationName = (orgName: string) => {
  return /^[\p{ASCII}]{3,64}$/u.test(orgName)
}

// TODO double check on catch behavior and properly clear and handle error.
//      no function should have side effect or ever call window.location.replace
//      in isolation
const setActiveOrganization = async (namespace: string | undefined) => {
  try {
    const { data } = await fetchOrganization(namespace)
    activeOrganization.value = data
    // track organization change
    await useAnalytics().identifyUser()
  } catch {
    // TODO this is a side effect, should not exist and should be properly handled
    clearLastViewedOrgIns()
    // cannot use router as router may not be initialized.
    window.location.replace(window.location.origin)
    activeOrganization.value = undefined
  }
}

const setSelfSubjectUserReview = async (isUseCache = true) => {
  if (!selfSubjectUserReview.value.status || !isUseCache) {
    try {
      const res = await fetchSelfSubjectUserReview()
      selfSubjectUserReview.value = res.data
    } catch {
      selfSubjectUserReview.value = {}
    }
  }
}

const resetOrganization = () => {
  activeOrganization.value = undefined
  selfSubjectUserReview.value = {}
}

const createOrganization = async ({
  displayName,
  awsMarketplaceToken,
  suger,
  metadata
}: {
  displayName: string
  awsMarketplaceToken?: LocationQueryValue | LocationQueryValue[]
  suger?: {
    entitlementId: LocationQueryValue | LocationQueryValue[]
    partner: LocationQueryValue | LocationQueryValue[]
  }
  metadata?: Record<string, string>
}) => {
  if (!isValidOrganizationName(displayName)) {
    throw Error(t('organization.InvalidOrganizationName'))
  }
  const { createSugerEntitlementReview, createSubscriptionIntent } = useSubscription()
  const isAws = typeof awsMarketplaceToken === 'string' ? awsMarketplaceToken : undefined
  const isSuger = suger && typeof suger.entitlementId === 'string' ? suger.entitlementId : undefined

  try {
    if (isSuger) {
      const review = await createSugerEntitlementReview(isSuger)
      // sugerEntitlementId already in use!
      if (review?.status?.organization) {
        throw Error(
          t('organization.entitlementAlreadyExists')
            .replace('$SUGER_PARTNER', suger?.partner?.toString() ?? '')
            .replace('$ENTITLEMENT', suger?.entitlementId?.toString() ?? '')
        )
      }
    }

    const spec: V1alpha1SelfRegistrationSpec = {
      displayName,
      type: 'stripe'
    }
    if (isAws) {
      spec.type = 'aws'
      spec.aws = {
        registrationToken: atob(isAws) || ''
      }
    } else if (isSuger) {
      spec.type = 'suger'
      spec.suger = {
        entitlementID: isSuger
      }
    }
    if (metadata) {
      spec.metadata = metadata
    }
    const createOrganizationBody: V1alpha1SelfRegistration = {
      spec
    }
    const api = useCloudApi()
    useAnalytics().identifyUser()
    const { data } = await api.createSelfRegistration(createOrganizationBody)

    // clear cached org metadata such as suger and aws marketplace
    clearCachedOrgMetadata()

    // is Suger sign up, link entitlement to subscription intent
    if (isSuger && data?.metadata?.name) {
      await createSubscriptionIntent(data.metadata.name, {
        entitlementID: isSuger,
        partner: suger?.partner as string // will throw error if undefined
      })
    }

    return data
  } catch (e) {
    throw getErrorMessage(e, 'createOrganization Error')
  }
}

export const init = (initialState: PulsarState) => {
  const { organization } = usePulsarState()
  const { isRbacUpdating, can } = rbacHelper()

  const valueChanged = async ([org, ab]: [
    string | undefined,
    boolean | undefined
  ]): Promise<void> => {
    if (!org) {
      activeOrganization.value = undefined
      lastOrg = undefined
      return
    }
    if (ab) {
      return
    }

    if (org !== lastOrg && can({ v1: { action: view, subject: Organization } })) {
      await setActiveOrganization(org)
    }
    lastOrg = org
  }

  watch([organization, isRbacUpdating], valueChanged)

  return valueChanged([initialState.organization, isRbacUpdating.value])
}

export const getStripePortalUrl = async (org: string) => {
  const res = await createCustomerPortalRequest(org)
  if (!res.data.status?.url) {
    throw Error('failed to fetch stripe portal url')
  }

  return res.data.status?.url
}

export const openBillingAndPayment = async () => {
  const org = activeOrganization.value?.metadata?.name
  if (!org) {
    return
  }

  if (isSugerOrganization.value) {
    if (sugerPartner.value === 'aws') {
      window.open('https://aws.amazon.com/', '_blank')
    } else if (sugerPartner.value === 'gcp') {
      window.open('https://console.cloud.google.com/marketplace/', '_blank')
    } else {
      throw new Error(t('error.unknownPartner'))
    }
  } else if (billingType.value === 'stripe') {
    const stripeUrl = await getStripePortalUrl(org)
    window.open(stripeUrl, '_blank')
  } else if (billingType.value === 'aws') {
    window.open('https://aws.amazon.com/', '_blank')
  }
}

const isDiscountEndBannerDisplay = computed(() => {
  if (getAppliedDiscountValueCent.value === 0) {
    // discount was never configured
    return false
  }
  if (organizationHasBillingMethod.value) {
    // billing metthod is set, either discount is active or payment method is set
    return false
  }

  return true
})

const _isDiscountBannerClosed = ref<string | undefined>(undefined)
const isDiscountBannerClosed = computed({
  get() {
    const cache = localStorage.getItem(
      `intro-discount-banner-${activeOrganization.value?.metadata?.name}`
    ) as string
    return _isDiscountBannerClosed.value === undefined ? cache : _isDiscountBannerClosed.value
  },
  set(val: string) {
    _isDiscountBannerClosed.value = val
  }
})

const adminsForTenants = ref<ReturnType<typeof getAdminsForTenants> | undefined>(undefined)
const fetchAdminsForTenants = () => {
  if (activeOrganization.value?.metadata?.name) {
    adminsForTenants.value = getAdminsForTenants(activeOrganization.value?.metadata?.name)
  } else {
    adminsForTenants.value = undefined
  }
  return adminsForTenants.value
}
watch(activeOrganization, () => fetchAdminsForTenants)

export const useOrganization = () => {
  return {
    activeOrganization,
    setActiveOrganization,
    billingType,
    displayName,
    organizationReady,
    setSelfSubjectUserReview,
    selfSubjectUsers,
    resetOrganization,
    init,
    createOrganization,
    isSugerOrganization,
    organizationHasValidSubscription,
    organizationHasBillingMethod,
    openBillingAndPayment,
    getStripePortalUrl,
    organizationOptions,
    sugerPartner,
    getAppliedDiscountValueCent,
    isActiveOrgMaintenanceWindowEditable,
    isDiscountEndBannerDisplay,
    isDiscountBannerClosed,
    adminsForTenants,
    fetchAdminsForTenants
  }
}
