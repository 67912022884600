import { admin, type AppAbility, type action as Action, useRbac } from '@/composables/useRbac'
import { type Permission, authorize, useRbacV2 } from '@/composables/useRbacV2'
import type { SRN } from '@streamnative/unified-rbac/src/proto/condition'

export interface RbacQueryV1 {
  AND?: Array<{ action: Action; subject: any }>
  OR?: Array<{ action: Action; subject: any }>
  NOT?: Array<{ action: Action; subject: any }>
}

export interface RbacQueryV2 {
  AND?: Array<{ permission: Permission; srn?: SRN }>
  OR?: Array<{ permission: Permission; srn?: SRN }>
  NOT?: Array<{ permission: Permission; srn?: SRN }>
}

export interface RbacParam {
  v1?:
    | {
        action: Action
        subject: any
      }
    | RbacQueryV1
  v2?:
    | {
        permission: Permission
        srn?: SRN
      }
    | RbacQueryV2
}

export const isRbacUpdating = computed(() => {
  return useRbac().abilityUpdating.value || useRbacV2().rbacV2Updating.value
})

export const isRbacEnabled = computed(() => {
  return useRbac().enabled.value || useRbacV2().rbacV2Enabled.value
})

export const canV1 = (
  query:
    | {
        action: Action
        subject: any
      }
    | RbacQueryV1,
  ability?: AppAbility,
  debug?: any
) => {
  const snAbility = ability ?? useRbac().snAbility
  let permitted = true

  const _query = typeof query === 'object' && 'action' in query ? { AND: [query] } : query

  if (_query.AND) {
    permitted &&= _query.AND.every(p => {
      return snAbility.can(admin, p.subject) || snAbility.can(p.action, p.subject)
    })
  }

  if (_query.OR) {
    permitted &&= _query.OR.some(p => {
      return snAbility.can(admin, p.subject) || snAbility.can(p.action, p.subject)
    })
  }

  if (_query.NOT) {
    permitted &&= _query.NOT.every(p => {
      return !snAbility.can(p.action, p.subject)
    })
  }

  debug &&
    console.log(
      `【V1】Rbac Context: ${JSON.stringify(debug, null, 2)}\n---\n`,
      `<${permitted}>: ${JSON.stringify(query, null, 2)}\n---\n`
    )

  return permitted
}

export const canV2 = (
  query:
    | {
        permission: Permission
        srn?: SRN
      }
    | RbacQueryV2,
  debug?: any
) => {
  let permitted = true

  const _query = typeof query === 'object' && 'permission' in query ? { AND: [query] } : query

  if (_query.AND) {
    permitted &&= _query.AND.every(p => {
      return authorize(p)
    })
  }

  if (_query.OR) {
    permitted &&= _query.OR.some(p => {
      return authorize(p)
    })
  }

  if (_query.NOT) {
    permitted &&= _query.NOT.every(p => {
      return !authorize(p)
    })
  }

  debug &&
    console.log(
      `【V2】Rbac Context: ${JSON.stringify(debug, null, 2)}\n---\n`,
      `<${permitted}>: ${JSON.stringify(query, null, 2)}\n---\n`
    )
  return permitted
}

export const can = ({ v2, v1 }: RbacParam, debug?: any) => {
  const { enabled } = useRbac()
  const { rbacV2Enabled } = useRbacV2()

  if (rbacV2Enabled.value && enabled.value) {
    debug &&
      console.error(
        `【ERROR】Rbac Context: ${JSON.stringify(debug, null, 2)}\n---\n`,
        'Both RbacV1 and RbacV2 enabled, this is not allowed\n---\n'
      )
  }

  if (rbacV2Enabled.value) {
    if (v2) {
      return canV2(v2, debug)
    }
    debug &&
      console.log(
        `【V2】Rbac Context: ${JSON.stringify(debug, null, 2)}\n---\n`,
        `<true>: no permission asked\n---\n`
      )
    return true
  }

  if (enabled.value) {
    if (v1) {
      return canV1(v1, undefined, debug)
    }
    debug &&
      console.log(
        `【V1】Rbac Context: ${JSON.stringify(debug, null, 2)}\n---\n`,
        `<true>: no permission asked\n---\n`
      )
    return true
  }
  debug &&
    console.log(
      `【NONE】Rbac Context: ${JSON.stringify(debug, null, 2)}\n---\n`,
      `No Rbac enabled\n---\n`
    )
  return true
}

export const rbacHelper = () => {
  return {
    isRbacUpdating,
    isRbacEnabled,
    canV1,
    canV2,
    can
  }
}
