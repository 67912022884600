import {
  useTenantsPulsarAdmin,
  useTenantsPulsarAdminWithSpecificInstance
} from '@/composables/pulsarAdmin'
import type { TenantInfo } from '@streamnative/pulsar-admin-client-typescript'
import { useCloudApi } from '@/composables/cloudApi'
import { tenantsAdminApiIsAvailable, usePluginsApi } from '@/composables/pulsarAdmin'

const buildTenantsPulsarAdmin = (
  organization: string | undefined = usePulsarState().organization.value,
  clusterUid: string | undefined = usePulsarState().clusterUid.value
) => {
  if (!organization) {
    throw Error(`Organization is not set.`)
  }
  if (!clusterUid) {
    throw Error(`Cluster is not set.`)
  }
  return useTenantsPulsarAdmin(organization, clusterUid)
}

const buildPluginTenantsPulsarAdmin = (
  organization: string | undefined = usePulsarState().organization.value,
  clusterUid: string | undefined = usePulsarState().clusterUid.value,
  instance: string | undefined = usePulsarState().instance.value
) => {
  return usePluginsApi(organization, clusterUid, instance)
}

export const fetchTenants = async ({
  organization,
  clusterUid
}: {
  organization?: string
  clusterUid?: string
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.get()
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenants Error')
  }
}

export const fetchAdministeredTenants = async (
  organization: string,
  clusterUid: string,
  instance: string
) => {
  if (await tenantsAdminApiIsAvailable(organization, clusterUid, instance)) {
    const pluginAdmin = buildPluginTenantsPulsarAdmin(organization, clusterUid, instance)
    return await pluginAdmin.getAdminTenants()
  }
}

export const fetchTenantsForSpecificInstance = async ({
  organization,
  instance,
  clusterUid
}: {
  organization?: string
  instance?: string
  clusterUid?: string
}) => {
  if (organization === undefined || instance === undefined || clusterUid === undefined) {
    throw Error('Invalid parameters for fetchTenantsForSpecificInstance')
  }
  const api = useTenantsPulsarAdminWithSpecificInstance(organization, clusterUid, instance)
  return await api.get()
}

export const fetchTenantAdminWithSpecificInstance = async ({
  organization,
  clusterUid,
  instance,
  tenant
}: {
  organization: string
  clusterUid: string
  instance: string
  tenant: string
}) => {
  try {
    const api = useTenantsPulsarAdminWithSpecificInstance(organization, clusterUid, instance)
    return await api.getTenantAdmin(tenant)
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenantAdminWithSpecificInstance Error')
  }
}

export const updateTenantAdminWithSpecificInstance = async ({
  organization,
  clusterUid,
  instance,
  tenant,
  data
}: {
  organization: string
  clusterUid: string
  instance: string
  tenant: string
  data: TenantInfo
}) => {
  try {
    if (tenant === '' || !data) {
      throw new Error('Cannot update tenant admin without tenant or data')
    }

    const api = useTenantsPulsarAdminWithSpecificInstance(organization, clusterUid, instance)
    return await api.updateTenant(tenant, data)
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenantAdminWithSpecificInstance Error')
  }
}

export const fetchTenantAdmin = async ({
  organization,
  clusterUid,
  tenant
}: {
  organization?: string
  clusterUid?: string
  tenant: string
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.getTenantAdmin(tenant)
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenantAdmin Error')
  }
}

// returns the following
// {
//   instanceName: { clusters: [{tenant, uid, name, adminRoles}] }
// }
//
export const getAdminsForTenants = async (
  organization: string
): Promise<
  Record<
    string,
    { clusters: Array<{ name: string; tenant: string; uid: string; adminRoles: Array<string> }> }
  >
> => {
  const { data } = await useCloudApi().listNamespacedPulsarCluster(organization)
  const clusters = data.items

  const tenInCluster: Record<
    string,
    { clusters: Array<{ tenant: string; uid: string; name: string; adminRoles: Array<string> }> }
  > = {}
  for (const cluster of clusters) {
    const instance = cluster.spec?.instanceName
    if (!instance) {
      throw new Error('Instance for cluster is invalid')
    }
    if (!cluster?.metadata?.uid) {
      throw new Error('Cluster uid is undefined, unable to continue')
    }
    if (!cluster.metadata.name) {
      throw new Error('Cluster has no name, error')
    }

    // this will be slow due to duplicates from clusters having same instance
    try {
      const ftRes = await fetchTenantsForSpecificInstance({
        organization,
        instance,
        clusterUid: cluster.metadata.uid
      })

      const tenantAdmins = await Promise.all(
        ftRes.data.map((resTenant: string) => {
          if (!cluster.metadata?.uid) {
            throw new Error('Cluster is invalid. No UID.')
          }

          if (!cluster.metadata?.name) {
            throw new Error('Cluster is invalid. No Name.')
          }

          return fetchTenantAdminWithSpecificInstance({
            organization,
            clusterUid: cluster.metadata.uid,
            instance,
            tenant: resTenant
          })
        })
      )

      // results should be in order between both calls so
      // it should be fine to just iterate over it again
      for (let i = 0; i < tenantAdmins.length; i++) {
        const resTenant = ftRes.data[i]

        if (!tenInCluster[instance]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          tenInCluster[instance] = {}
          tenInCluster[instance].clusters = []
        }

        tenInCluster[instance].clusters.push({
          uid: cluster.metadata.uid,
          name: cluster.metadata.name,
          adminRoles: tenantAdmins[i].data.adminRoles || [],
          tenant: resTenant
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return tenInCluster
}

export const createTenant = async ({
  organization,
  clusterUid,
  tenant,
  data
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  data: TenantInfo
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.createTenant(tenant, data)
  } catch (e) {
    throw getErrorMessage(e, 'createTenant Error')
  }
}

export const updateTenant = async ({
  organization,
  clusterUid,
  tenant,
  data
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  data: TenantInfo
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.updateTenant(tenant, data)
  } catch (e) {
    throw getErrorMessage(e, 'updateTenant Error')
  }
}

export const deleteTenant = async ({
  organization,
  clusterUid,
  tenant
}: {
  organization?: string
  clusterUid?: string
  tenant: string
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.deleteTenant(tenant)
  } catch (e) {
    throw getErrorMessage(e, 'createTenant Error')
  }
}
