import type { Ref } from 'vue'
import type {
  V1alpha1User,
  V1alpha1UserSpec,
  V1ObjectMeta
} from '@streamnative/cloud-api-client-typescript'
import { createUser, deleteUser, fetchUsers } from '@/api/users'
import { i18n } from '@/lang'
import { auth } from '@/auth'
import type { PulsarState } from './usePulsarState'
const { t } = i18n.global
import { User } from './useRbac'

export interface User extends UserCore {
  organization: string
  invited: boolean
  accepted: boolean
  isUser: boolean
  copyLink: string
  uid: string | undefined // type is wrong in client, uid will never be undefined from a retrieved valid object
}

export interface UserCore {
  name: string
  email: string
}

let lastOrg: string | undefined = undefined
const users: Ref<User[]> = ref([])

const userNamesList = computed(() => {
  return users.value.map(user => user.name)
})

const annotationInviteLink = 'annotations.cloud.streamnative.io/invitation-data-invitationLink'
const annotationInvitee = 'annotations.cloud.streamnative.io/invitation-data-invitee'
const annotationInviter = 'annotations.cloud.streamnative.io/invitation-data-inviter'
const accepted = 'accepted'

export const getUserToInvite = (org: string, payload: UserCore) => {
  const { user } = auth
  const invitationLink = `https://${window.location.host}`
  return {
    apiVersion: 'cloud.streamnative.io/v1alpha1',
    kind: 'User',
    metadata: {
      name: payload.name,
      namespace: org,
      annotations: {
        [annotationInviteLink]: invitationLink,
        [annotationInvitee]: payload.name,
        [annotationInviter]: user.value?.email
      } as { [key: string]: string }
    } as V1ObjectMeta,
    spec: {
      email: payload.email,
      invitation: {
        decision: accepted
      }
    } as V1alpha1UserSpec
  } as V1alpha1User
}

const isLoadingUsers = ref(false)
const getUsers = async (organization?: string | undefined) => {
  if (!organization) {
    organization = usePulsarState().mustOrganization()
  }

  try {
    isLoadingUsers.value = true
    const result = await fetchUsers(organization)

    if ('error' in result) {
      throw Error(result.error)
    }

    const { user } = auth

    const _users: Array<User> = []
    result.items.forEach(({ metadata, spec }) => {
      if (metadata && spec) {
        _users.push({
          name: metadata.name ?? spec.email,
          organization: metadata.namespace ?? 'N/A',
          email: spec.email,
          invited: !!spec.invitation,
          accepted: spec?.invitation?.decision === accepted,
          isUser: metadata.name === user.value?.email,
          copyLink: metadata?.annotations?.[annotationInviteLink] ?? '',
          uid: metadata.uid
        })
      }
    })

    users.value = _users
  } finally {
    isLoadingUsers.value = false
  }
}

const inviteUser = async (payload: UserCore) => {
  // construct user object
  const org = usePulsarState().mustOrganization()
  const user = getUserToInvite(org, payload)
  const userResult = await createUser(org, user)
  if ('error' in userResult) {
    throw Error(userResult.error ?? t('user.inviteUserFailedNotification'))
  }

  const { metadata, spec } = userResult
  if (!metadata?.name || !metadata?.namespace || !spec?.email) {
    throw Error(useI18n().t('user.inviteUserFailedNotification'))
  }
  users.value = [
    ...users.value,
    {
      name: metadata?.name,
      organization: metadata?.namespace,
      email: spec?.email,
      invited: true,
      accepted: true,
      isUser: false,
      copyLink: metadata?.annotations?.[annotationInviteLink] ?? '',
      uid: metadata.uid
    }
  ]

  return metadata.uid
}

// TODO remove users from any tenant admin roles on clusters
//      will require list of all instances, getting tokens for those, list of all clusters for instances,
//      getting admins from those, removing this user from any present,
//      and updating each cluster again
//      also error handling
const removeUser = async (username: string) => {
  const organization = usePulsarState().mustOrganization()
  const result = await deleteUser(organization, username)

  if ('error' in result) {
    throw Error(result?.error ?? t('user.deleteUserFailedNotification'))
  }

  const toDelete = users.value.findIndex(u => u.name === username)
  if (toDelete > -1) {
    users.value.splice(toDelete, 1)
  }
}

export const init = (initialState: PulsarState) => {
  const { organization } = usePulsarState()
  const { isRbacUpdating } = rbacHelper()
  const valueChanged = async ([org, ability]: [string | undefined, boolean | undefined]) => {
    if (ability) {
      return
    }
    if (!org) {
      users.value = []
      lastOrg = undefined
      return
    }

    if (org !== lastOrg) {
      const { canDescribeUserList } = rbacManager()
      if (canDescribeUserList()) {
        await getUsers(org)
      }
    }
    lastOrg = org
  }

  watch([organization, isRbacUpdating], valueChanged)
  return valueChanged([initialState.organization, isRbacUpdating.value])
}

export const _useUser = {
  users,
  removeUser,
  inviteUser,
  getUsers,
  init,
  userNamesList,
  getUserToInvite,
  isLoadingUsers
}

export const useUser = () => {
  return _useUser
}
