import { fetchTenants, fetchAdministeredTenants } from '@/api/tenants'
import { fetchNamespacePolicies, fetchNamespaces } from '@/api/namespaces'
import { useError } from '@/composables/provider'
import type { Policies } from '@streamnative/pulsar-admin-client-typescript'
import type { PulsarState } from './usePulsarState'

const errorGettingTenants = ref<{ message: string; e: unknown } | undefined>(undefined)
const errorGettingNamespaces = ref<{ message: string; e: unknown } | undefined>(undefined)

// TODO this file should be renamed to useTenants.ts and we should split the
//      tenant and namespace logic into separate files

let lastSeen: string | undefined = undefined

interface TenantNamespaces {
  [tenant: string]: { [namespace: string]: Policies }
}
const tenantNamespaces = ref<TenantNamespaces>({})
const tenantNames = computed<string[]>(() => Object.keys(tenantNamespaces.value).sort())

const tenantsLoading = ref(false)
const setTenants = async (params?: { organization?: string; clusterUid?: string }) => {
  const { mustOrganization, mustClusterUid } = usePulsarState()
  try {
    tenantsLoading.value = true
    const result: TenantNamespaces = {}
    const tenantsList = (
      await fetchTenants({
        organization: params?.organization || mustOrganization(),
        clusterUid: params?.clusterUid || mustClusterUid()
      })
    ).data
    errorGettingTenants.value = undefined
    tenantsList.forEach(tenant => {
      result[tenant] = {}
    })
    tenantNamespaces.value = result
  } catch (e) {
    useError(e)
    if (Object.keys(tenantNamespaces.value).length > 0) {
      tenantNamespaces.value = {}
    }
    errorGettingTenants.value = { e, message: getErrorMessage(e) }
  } finally {
    tenantsLoading.value = false
  }
}

// TODO we need to set additional metadata on these to indicate you cannot
//      actually 'admin' the tenant, you can only admin under the tenant e.g
//      the namespaces, etc.
const setAdministeredTenants = async (
  organization: string,
  clusterUid: string,
  instance: string
) => {
  try {
    const result: TenantNamespaces = {}
    const tenantsList = await fetchAdministeredTenants(organization, clusterUid, instance)
    if (tenantsList) {
      const { data: tenants } = tenantsList
      tenants.forEach(tenant => {
        result[tenant] = {}
      })
      tenantNamespaces.value = result
    }
    errorGettingTenants.value = undefined
  } catch (e) {
    useError(e)
    if (Object.keys(tenantNamespaces.value).length > 0) {
      tenantNamespaces.value = {}
    }
    errorGettingTenants.value = { e, message: getErrorMessage(e) }
  }
}

const namespacesLoading = ref(false)
const setTenantNamespaces = async (tenant?: string) => {
  namespacesLoading.value = true
  try {
    tenant = tenant || usePulsarState().mustTenant()
    if (Object.keys(tenantNamespaces.value).length === 0) {
      tenantNamespaces.value[tenant] = {}
    }
    const result: { [namespace: string]: Policies } = {}
    const namespaceList = (await fetchNamespaces({ tenant })).data
    namespaceList.forEach(namespace => {
      result[namespace] = {}
    })
    tenantNamespaces.value[tenant] = result
    errorGettingNamespaces.value = undefined
  } catch (e) {
    console.error(e)
    useError(e)
    if (tenant && Object.keys(tenantNamespaces.value[tenant]).length > 0) {
      tenantNamespaces.value[tenant] = {}
    }
    errorGettingNamespaces.value = { e, message: getErrorMessage(e) }
  } finally {
    namespacesLoading.value = false
  }
}

const setNamespacePolicies = async (params: { tenant: string; namespace: string }) => {
  const { mustOrganization, mustClusterUid } = usePulsarState()

  const policies = (
    await fetchNamespacePolicies({
      organization: mustOrganization(),
      clusterUid: mustClusterUid(),
      ...params
    })
  ).data

  if (!(params.tenant in tenantNamespaces.value)) {
    tenantNamespaces.value[params.tenant] = {}
  }
  tenantNamespaces.value[params.tenant][params.namespace] = policies
}

export const init = (initialState: PulsarState) => {
  const { organization, instance, clusterUid, tenant } = usePulsarState()
  const { isRbacUpdating } = rbacHelper()
  const { canDescribeTenantList, canDescribeNamespaceList } = rbacManager()
  const valueChanged = async ([org, instance, clusterUid, ten, ab]: [
    string | undefined,
    string | undefined,
    string | undefined,
    string | undefined,
    boolean | undefined
  ]) => {
    if (ab) {
      return
    }

    if (!org || !instance || !clusterUid) {
      tenantNamespaces.value = {}
      lastSeen = undefined
      return
    }

    const seen = `${org}/${instance}/${clusterUid}/${ten}`
    const tSeen = seen.substring(0, seen.lastIndexOf('/'))
    const tLastSeen = lastSeen?.substring(0, lastSeen?.lastIndexOf('/'))
    if (tSeen !== tLastSeen && canDescribeTenantList()) {
      await setTenants({ organization: org, clusterUid })
    }
    if (ten && seen !== lastSeen && canDescribeNamespaceList()) {
      await setTenantNamespaces(ten)
    }
    lastSeen = seen
  }

  watch([organization, instance, clusterUid, tenant, isRbacUpdating], valueChanged)
  return valueChanged([
    initialState.organization,
    initialState.instance,
    initialState.clusterUid,
    initialState.tenant,
    isRbacUpdating.value
  ])
}

const systemTenantNamespaces = [
  'sn/system',
  'pulsar/system',
  'public/__kafka',
  'public/__kafka_schemaregistry'
]

export const useTenantNamespace = () => {
  return {
    setAdministeredTenants,
    setTenants,
    tenantsLoading,
    namespacesLoading,
    setTenantNamespaces,
    tenantNames,
    tenantNamespaces,
    removeTenant: (name: string) => {
      delete tenantNamespaces.value[name]
    },
    addTenant: (name: string) => {
      tenantNamespaces.value[name] = {}
    },
    removeNamespace: (tenant: string, name: string) => {
      delete tenantNamespaces.value[tenant][name]
    },
    addNamespace: (tenant: string, name: string) => {
      tenantNamespaces.value[tenant][name] = {}
    },
    setNamespacePolicies,
    systemTenantNamespaces,
    isSystemTenantNamespace: (tenant: string, namespace: string) =>
      systemTenantNamespaces.includes(tenant + '/' + namespace),
    init,
    errorGettingTenants,
    errorGettingNamespaces
  }
}
