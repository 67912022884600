import type { LocationQuery, RouteLocationNormalized, RouteRecordName } from 'vue-router'
import { createRouter as createVueRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAnalytics } from '@/composables/analytics'
import type { PulsarState } from '@/composables/usePulsarState'
import { useMetadata } from '@/composables/useMetadata'
import { useUserMetadata } from '@/composables/useUserMetadata'
import { buildOrgRules, useRbac } from '@/composables/useRbac'
import { buildOrgRbac, useRbacV2 } from '@/composables/useRbacV2'
import { auth, authGuard } from '@/auth'
// TODO should we also clear the orgmetadata and usermetadata on unauthn?
import {
  getLastViewedOrgIns,
  setLastViewedOrgIns,
  setSugerEntitlementId,
  setSugerPartner
} from '@/utils/localStorageHelper'
import { until } from '@vueuse/core'
import { NEW_RBAC_FLAG, RBAC_V2_FLAG } from '@/auth/ld/ld'
import type { App } from 'vue'
import type { LDClient } from 'launchdarkly-js-client-sdk'
// apply last visited org and instance if applicable before router initialization
const [lastVisitedOrg, lastVisitedIns] = getLastViewedOrgIns()
const searchParams = new URLSearchParams(window.location.search)

if (!searchParams.get('org') && lastVisitedOrg) {
  searchParams.set('org', lastVisitedOrg)
}
if (!searchParams.get('instance') && lastVisitedIns) {
  searchParams.set('instance', lastVisitedIns)
}
if (searchParams.get('sugerEntitlementId') && searchParams.get('partner')) {
  setSugerEntitlementId(searchParams.get('sugerEntitlementId') as string)
  setSugerPartner(searchParams.get('partner') as string)
}
if (searchParams.toString() !== '') {
  window.history.replaceState(history.state, '{}', `?${searchParams.toString()}`)
}

// Technically newOrganization can be an array of values since query params can
// be an array, but we're not using it that way.
const identifyLDContext = async (newOrganization: string | undefined, orgLDClient: LDClient) => {
  let context = {
    kind: 'organization',
    key: 'shared',
    anonymous: true
  }
  const { enabled } = useRbac()
  const { rbacV2Enabled } = useRbacV2()

  if (
    newOrganization === undefined ||
    newOrganization === '' ||
    newOrganization === null ||
    newOrganization === 'undefined'
  ) {
    await orgLDClient.identify(context)
    enabled.value = await orgLDClient.variation(NEW_RBAC_FLAG, false)
    rbacV2Enabled.value = await orgLDClient.variation(RBAC_V2_FLAG, false)
    return
  }

  context = {
    key: newOrganization,
    kind: 'organization',
    anonymous: false
  }

  // TODO identify returns all feature flags, might not need variation
  await orgLDClient.identify(context)
  enabled.value = await orgLDClient.variation(NEW_RBAC_FLAG, false)
  rbacV2Enabled.value = await orgLDClient.variation(RBAC_V2_FLAG, false)
  if (newOrganization) {
    await buildOrgRules(newOrganization)
    await buildOrgRbac(newOrganization)
  }
}

export function createRouter(app: App) {
  const router = createVueRouter({
    // TODO this import has a typescript error and will fail if we do not
    //      use proper esbuild options to produce the right output such as
    //      esnext as our target
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
  })

  router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (!to.meta.requiresAuth) {
      return true
    }

    const loading = useLoading()

    await until(() => app.config.globalProperties['$auth0'].isLoading.value).toBe(false)

    // TODO waitUntilReady can fail and does not throw an error but the client
    //      can not be initialized, waitUntilInitialize fails but it would throw an
    //      exception
    await app.config.globalProperties['$ldClient'].waitUntilReady()

    if (!app.config.globalProperties['$auth0'].isAuthenticated.value) {
      // TODO double check on this, might need to clear state
      // clearLastViewedOrgIns()
      loading.close()
      return await authGuard(to)
    }

    if (
      app.config.globalProperties['$auth0'].isAuthenticated.value &&
      to.name !== 'SignupFlowPage'
    ) {
      const isUserHasOrg = await useMetadata().isUserHasOrg()
      const isUserHasMetadata = await useUserMetadata().isUserHasMetadata()
      if (auth.user?.value?.email_verified && (!isUserHasOrg || !isUserHasMetadata)) {
        // If a user doesn't have org or doesn't have metadata, this means that we
        // need to redirect our user and have them fill out necessary details.
        loading.close()
        return {
          name: 'SignupFlowPage',
          query: { ...to.query, destination: '/' },
          replace: true
        }
      }
    }

    // TODO this does a LD check on EVERY route change, this is likely not needed
    //      it seems to be masking other issues on ability updates
    if (to.name !== 'OrganizationsPage') {
      await identifyLDContext(to.query.org as string, app.config.globalProperties['$ldClient'])
    } else {
      await identifyLDContext(undefined, app.config.globalProperties['$ldClient'])
    }

    const { isRbacUpdating } = rbacHelper()
    await until(() => isRbacUpdating.value).toBe(false)

    const { sync } = usePulsarState()
    const desiredPulsarState = getDesiredPulsarState(to)
    await sync(desiredPulsarState)

    const [q, updateNeeded] = resolveDefaultQueryParams(to, from)

    if (!updateNeeded) {
      setLastViewedOrgIns(to)
      loading.close()
      return
    }

    loading.close()
    return
  })

  router.beforeResolve(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    // URL check, for example, missing some query params
    if (to.meta.checkFn && !(await to.meta.checkFn({ to }))) {
      useError('Invalid URL, back to organizations page')
      await router.replace({
        name: 'OrganizationsPage',
        query: undefined
      })
    }

    // feature check, for example, disabled some function from LD
    if (
      to.meta.disableFn &&
      (await to.meta.disableFn({ to, ldClient: app.config.globalProperties['$ldClient'] }))
    ) {
      if (!to.meta.replaceFn) {
        useError('You can not access this page, back to organizations page')
        await router.replace({
          name: 'OrganizationsPage',
          query: undefined
        })
      } else {
        const _replace = await to.meta.replaceFn({ to })
        if (typeof _replace === 'string') {
          await router.replace({
            name: _replace as RouteRecordName,
            params: to.params,
            query: to.query
          })
        } else {
          await router.replace({
            name: _replace.name as RouteRecordName,
            params: _replace.params,
            query: _replace.query
          })
        }
      }
    }

    // auth check, for example, user does not have permission to access some pages
    if (to.meta.authFn && !(await to.meta.authFn({ to }))) {
      if (!to.meta.replaceFn) {
        useError('You are not authorized to access this page, back to organizations page')
        await router.replace({
          name: 'OrganizationsPage',
          query: undefined
        })
      } else {
        const _replace = await to.meta.replaceFn({ to })
        if (typeof _replace === 'string') {
          await router.replace({
            name: _replace as RouteRecordName,
            params: to.params,
            query: to.query
          })
        } else {
          await router.replace({
            name: _replace.name as RouteRecordName,
            params: _replace.params,
            query: _replace.query
          })
        }
      }
    }
  })

  return router
}

const getDesiredPulsarState = (to: RouteLocationNormalized): PulsarState => {
  return {
    organization: to.query.org ? (to.query.org as string) : undefined,
    instance: to.query.instance ? (to.query.instance as string) : undefined,
    clusterUid: to.query.cluster ? (to.query.cluster as string) : undefined,
    tenant: to.query.tenant ? (to.query.tenant as string) : undefined,
    namespace: to.query.namespace ? (to.query.namespace as string) : undefined,
    topic: to.query.topic ? (to.query.topic as string) : undefined
  }
}

type PulsarStateQueryParams = {
  query: {
    org?: string | undefined
    instance?: string | undefined
    tenant?: string | undefined
    namespace?: string | undefined
    cluster?: string | undefined
    topic?: string | undefined
  }
}

export const checkIfUpdateNeeded = (
  to: PulsarStateQueryParams
): [needsUpdate: boolean, desiredQuery: Record<string, string | undefined>] => {
  const desiredQuery: Record<string, string | undefined> = {}
  const { organization, instance, clusterUid, tenant, namespace, topic } = usePulsarState()

  desiredQuery.org = organization.value

  // these are to avoid ?org=abc&cluster=123 issue.  This happens if api query fetch
  // complets before route navigation completes
  if (desiredQuery.org) {
    desiredQuery.instance = instance.value
  }
  if (desiredQuery.instance) {
    desiredQuery.cluster = clusterUid.value
  }
  if (desiredQuery.cluster) {
    desiredQuery.tenant = tenant.value
  }
  if (desiredQuery.cluster && desiredQuery.tenant) {
    desiredQuery.namespace = namespace.value
  }
  if (desiredQuery.cluster && desiredQuery.tenant && desiredQuery.namespace) {
    desiredQuery.topic = topic.value
  }

  let isUpdateNeeded = false

  if ((desiredQuery.org ?? '') !== ((to.query.org ?? '') as string)) {
    desiredQuery.org = to.query.org as string
    isUpdateNeeded = true
  }
  if ((desiredQuery.instance ?? '') !== ((to.query.instance ?? '') as string)) {
    desiredQuery.instance = to.query.instance as string
    isUpdateNeeded = true
  }
  if ((desiredQuery.cluster ?? '') !== ((to.query.cluster ?? '') as string)) {
    desiredQuery.cluster = to.query.cluster as string
    isUpdateNeeded = true
  }
  if ((desiredQuery.tenant ?? '') !== ((to.query.tenant ?? '') as string)) {
    desiredQuery.tenant = to.query.tenant as string
    isUpdateNeeded = true
  }
  if ((desiredQuery.namespace ?? '') !== ((to.query.namespace ?? '') as string)) {
    desiredQuery.namespace = to.query.namespace as string
    isUpdateNeeded = true
  }
  if ((desiredQuery.topic ?? '') !== ((to.query.topic ?? '') as string)) {
    desiredQuery.topic = to.query.topic as string
    isUpdateNeeded = true
  }
  return [isUpdateNeeded, desiredQuery]
}

// setup tracking for all route transitions automatically.
// also, to set some of the "default" values when applicable and redirect users to it.
const resolveDefaultQueryParams = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
): [LocationQuery, boolean] => {
  useAnalytics().trackPage(to, from)
  // to has the query definition on it and each of the pulsar params may be blank/undefined at any point
  const [isUpdateNeeded, desiredQuery] = checkIfUpdateNeeded(to as PulsarStateQueryParams)

  const q = cleanQuery(desiredQuery)
  return [q, isUpdateNeeded]
}

// TODO this should also properly protect against injection attacks.
const cleanQuery = (q: Record<string, string | undefined>) => {
  return Object.entries(q)
    .filter(([, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      obj[key] = value
      return obj
    }, {})
}

export default createRouter
