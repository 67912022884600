import * as rudderanalytics from 'rudder-sdk-js'
import rudderConfig from 'Config/rudderstack_config.json'
import type { RouteLocationNormalized, NavigationFailure } from 'vue-router'
import type { PartitionedTopicStatsImpl } from '@streamnative/pulsar-admin-client-typescript'
import { getUserMetadata } from '@/composables/useUserMetadata'
const NON_COMPANY_DOMAINS = ['gmail.com', 'yahoo.com', 'hotmail.com']
const DEFAULT_RUDDER_HOST = 'https://hosted.rudderlabs.com'
import { auth } from '@/auth'

interface State {
  isMock: boolean
  lastIdentTs: number
}

const state = reactive<State>({
  isMock: true,
  lastIdentTs: 0
})

// this is required for type inference on line 43
// intercomSettings are the settings provided by intercom
// which get added to Window after rudderanalytics loads and
// becomes ready
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercomSettings: any
  }
}

// dumb hack to bypass TS4082: Default export of the module has or is using private name 'apiObject' error
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RudderApiObject extends rudderanalytics.apiObject {}

const apiKey = rudderConfig.apiKey
const rudderHost = rudderConfig.host || DEFAULT_RUDDER_HOST
// Create a new instance of the SDK client using members of the given options object
if (apiKey === '' || apiKey.startsWith('RUDDERSTACK')) {
  state.isMock = true
} else {
  state.isMock = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const siteDependentIntercomSettings: any = {}
  if (location.hostname === 'console.test.cloud.gcp.streamnative.dev') {
    siteDependentIntercomSettings['hide_default_launcher'] = true
  }
  // this should only ever run once. https://www.rudderstack.com/docs/sources/sdks/rudderstack-javascript-sdk/quick-start-guide/#using-the-exported-object
  rudderanalytics.load(apiKey, rudderHost)
  rudderanalytics.ready(() => {
    // custom intercom configuration
    window.intercomSettings = {
      ...window.intercomSettings,
      custom_launcher_selector: '#needHelpLauncher',
      ...siteDependentIntercomSettings
    }
  })
}

const track = (name: string, props: RudderApiObject = {}) => {
  if (state.isMock) {
    return
  }
  rudderanalytics.ready(() => {
    return rudderanalytics.track(name, concatProps(props) as rudderanalytics.apiObject)
  })
}

const concatProps = (props: RudderApiObject) => {
  const { organization, instance, clusterUid, tenant, namespace, topic } = usePulsarState()
  const { topicStats } = useTopic()

  const { activeInstance } = useInstance()
  let instanceType: 'managed' | 'hosted-paid' | 'hosted-free' = 'managed'
  if (activeInstance.value?.spec?.poolRef?.namespace === 'streamnative') {
    // as part of the new billing changes the type field is being deprecated and will be undefined
    // for paid clusters. free clusters should remain the same.
    instanceType = activeInstance.value.spec.type === 'free' ? 'hosted-free' : 'hosted-paid'
  }

  return {
    activeOrganization: organization.value,
    activeInstance: instance.value,
    activeClusterUid: clusterUid.value,
    activeTenant: tenant.value,
    activeNamespace: namespace.value,
    activeTopic: topic.value,
    // only want count if has activeTopic
    partitions: topic.value
      ? (topicStats.value as PartitionedTopicStatsImpl)?.partitions?.length
      : undefined,
    instanceType,
    ...props
  }
}

const identifyUser = async () => {
  const { userId, identInfo } = await convertUserMeta()
  if (userId) {
    if (Date.now() - state.lastIdentTs < 10000) {
      // skip doing an ident, as we just recently did one
      return
    }

    if (state.isMock) {
      state.lastIdentTs = Date.now()
      return
    }

    rudderanalytics.ready(() => {
      return rudderanalytics.identify(userId, identInfo, () => (state.lastIdentTs = Date.now()))
    })
  }
}

const trackPage = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  failure: void | NavigationFailure | undefined
) => {
  // type apiObject does not allow LocationQuery
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryWithoutNull = Object.entries(to.query).reduce((a: { [key: string]: any }, [k, v]) => {
    if (v && typeof v !== 'object') {
      a[k] = v
    }
    return a
  }, {})
  if (state.isMock) {
    return
  }
  rudderanalytics.ready(() => {
    if (failure) {
      rudderanalytics.page('Route Failure: ', String(to.name), {
        ...(concatProps({
          params: to.params,
          query: queryWithoutNull
        }) as rudderanalytics.apiObject),
        failure: failure.toString()
      })
    }

    rudderanalytics.page(
      '',
      String(to.name),
      concatProps({ params: to.params, query: queryWithoutNull }) as rudderanalytics.apiObject
    )
  })
}

const trackPageTab = async (
  category: string,
  pageName: string,
  tabIndex: string,
  isValidationFail = false
) => {
  const userMetadata = await convertUserMeta()
  rudderanalytics.ready(() => {
    rudderanalytics.page(category, pageName, { ...userMetadata, tabIndex, isValidationFail })
  })
}

const convertUserMeta = async () => {
  const user = auth.user.value
  // TODO once we unify user accounts, we need to change
  // this to be the email
  const userId = user?.sub
  if (!user?.email || !userId) {
    return { userId: '', identInfo: {} }
  }

  const extraMeta = user['https://streamnative.io/usermeta'] || {}
  // if we can't find a company name, we use the email
  // domain as company name, unless it is a known free email
  const emailDomain = user.email?.split('@').pop()
  let company = extraMeta.company
  if (!company && emailDomain && !NON_COMPANY_DOMAINS.includes(emailDomain)) {
    company = emailDomain
  }

  // calculate a full name, removing any empty params as needed
  let fullName
  if (user.given_name && user.family_name) {
    fullName = [user.given_name, user.family_name].filter(x => x.trim()).join(' ')
  } else {
    fullName = user.name
  }

  const { selfSubjectUsers, activeOrganization } = useOrganization()
  const { instances } = useInstance()
  const { clusterMap } = useCluster()
  const userMetadata = await getUserMetadata()
  // normalize the id props
  const identInfo: RudderApiObject = {
    userId: userId,
    email: user.email,
    firstName: user.given_name,
    lastName: user.family_name,
    name: fullName,
    emailVerified: user.email_verified,
    company: company,
    locale: user.locale,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
    is_cloud_user: true,
    cloud_organizations: selfSubjectUsers.value.map(u => u.organization.displayName).join(','),
    has_cloud_cluster: Object.keys(clusterMap.value).length > 0,
    has_cloud_instance: instances.value.length > 0,
    last_cloud_login: localStorage.getItem('lastLogin') ?? undefined,
    snc_full_name: userMetadata?.fullName,
    snc_is_email_sub: userMetadata?.isInfoEmailSubscribed,
    snc_is_newsletter_sub: userMetadata?.isNewsLetterSubscribed,
    snc_pulsar_knowledge: userMetadata?.pulsarKnowledge,
    snc_company_role: userMetadata?.role,
    snc_org_company_name: activeOrganization.value?.spec?.displayName,
    snc_org_company_info: activeOrganization.value?.spec?.metadata?.companyInfo,
    snc_org_company_size: activeOrganization.value?.spec?.metadata?.companySize
  }
  return { userId, identInfo }
}

export const _useAnalytics = {
  track,
  trackPage,
  trackPageTab,
  identifyUser
}

export const useAnalytics = () => {
  return _useAnalytics
}
