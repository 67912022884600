import { useAuthApi } from '@/composables/cloudApi'

export const fetchSelfSubjectRbacReview = (namespace: string) => {
  try {
    const api = useAuthApi()
    return api.createSelfSubjectRbacReview({ spec: { namespace } })
  } catch (e) {
    throw getErrorMessage(e, 'fetch SelfSubjectRbacReview Error')
  }
}
