import { createApp } from 'vue'
import { Loading, Notify, Quasar } from 'quasar'
import createRouter from '@/router'
import { i18n } from '@/lang' // Internationalization
import { createMetaManager } from 'vue-meta'
import { auth } from '@/auth'
import InlineSvg from 'vue-inline-svg'
import '@quasar/extras/material-icons/material-icons.css'
import '@/assets/quasar.css'
import './index.css'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import commonConfig from 'Config/common_config.json'
// our test plugin
import { abilitiesPlugin } from '@casl/vue'
import { OrgLDPlugin } from '@/auth/ld/ld'
import { useRbac } from '@/composables/useRbac'
import { e2eRoutes } from '@/router/routes'
import VueVirtualScroller from 'vue-virtual-scroller'

const app = createApp(App)

// At some point before 8/7/24 Quasar changed their API and
// we have not updated. This broke our build.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.use(Quasar, {
  plugins: {
    Notify,
    Loading
  }, // import Quasar plugins and add here
  config: {
    removeDefaultCss: true,
    brand: {
      primary: '#0A74F5',
      secondary: '#26A69A',
      accent: '#9C27B0',

      dark: '#1d1d1d',

      positive: '#1C8113',
      negative: '#C71F1F',
      info: '#31CCEC',
      warning: '#FFC233'
    },
    notify: { position: 'top' }, // default set of options for Notify Quasar plugin
    loading: { delay: 200 } // default set of options for Loading Quasar plugin
    // loadingBar: { ... }, // settings for LoadingBar Quasar plugin
    // ..and many more (check Installation card on each Quasar component/directive/plugin)
  }
})
const router = createRouter(app)

app.use(auth)
app.use(i18n)
app.use(createMetaManager())
app.component('InlineSvg', InlineSvg)
app.use(VueVirtualScroller)

if (location.hostname !== 'console.local.sn') {
  const integrations = [
    // TypeScript is wrong.
    // See https://github.com/getsentry/sentry-javascript/issues/10802
    // and https://docs.sentry.io/platforms/javascript/guides/vue/session-replay
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true
    }),
    Sentry.replayCanvasIntegration(),
    // Add browser profiling integration to the list of integrations
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration()
  ]

  if (location.hostname === 'console.test.cloud.gcp.streamnative.dev') {
    integrations.push(
      Sentry.feedbackIntegration({
        colorScheme: 'light'
      })
    )
  }

  Sentry.init({
    app,
    dsn: 'https://d1082fec54f343aaa123e78861a0387d@o1261076.ingest.sentry.io/6438081',
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    environment: location.hostname, // using hostname as environment

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations,
    tracePropagationTargets: ['console.test.cloud.gcp.streamnative.dev'],
    profilesSampleRate: 1.0
  })
} else {
  // If we're on dev WITH Cypress load routes used by cypress for e2e tests
  if (window.Cypress) {
    e2eRoutes.forEach(r => router.addRoute(r))
  }
}

app.use(router)

app.use(OrgLDPlugin, {
  clientSideID: commonConfig.launchDarklyId,
  context: { kind: 'organization', key: 'shared', anonymous: true },
  streaming: true
})

const { snAbility } = useRbac()
app.use(abilitiesPlugin, snAbility, {
  useGlobalProperties: true
})

// TODO router.isReady appears to just be masking issues and race conditions between
//      loaded components and the router.  We should look into a better solution.
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/always-return */
router.isReady().then(() => {
  app.mount('#app')
})
