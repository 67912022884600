import { useCloudApi } from '@/composables/cloudApi'
import type {
  CloudV1alpha1PoolRef,
  V1alpha1PulsarInstance
} from '@streamnative/cloud-api-client-typescript'
import { i18n } from '@/lang'
const { t } = i18n.global

export async function fetchInstances(namespace: string) {
  try {
    const api = useCloudApi()
    const res = await api.listNamespacedPulsarInstance(namespace)
    return res.data
  } catch (e) {
    throw Error(getErrorMessage(e, 'fetchInstance Error'))
  }
}

export async function fetchInstance(instance: string, namespace: string) {
  try {
    const api = useCloudApi()
    const res = await api.readNamespacedPulsarInstance(instance, namespace)
    return res.data
  } catch (e) {
    throw Error(getErrorMessage(e, 'fetchInstance Error'))
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function deleteInstance(payload: any) {
  try {
    const api = useCloudApi()
    const res = await api.deleteNamespacedPulsarInstance(payload.instanceName, payload.organization)
    return res.data
  } catch (e) {
    throw Error(getErrorMessage(e))
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function createInstance(payload: any) {
  const api = useCloudApi()

  if (!payload.poolRef) {
    const { data: listPooloptionData } = await api.listNamespacedPoolOption(payload.organization)
    const poolOptionItem = listPooloptionData.items.find(item => {
      return (
        item.spec?.cloudType === payload.cloudProvider &&
        item.spec?.locations !== undefined &&
        item.spec?.locations.length > 0
      )
    })
    const poolRef: CloudV1alpha1PoolRef | undefined = poolOptionItem
      ? poolOptionItem.spec?.poolRef
      : undefined
    if (poolRef === undefined) {
      throw Error(t('instance.errorCreating'))
    }
    payload.poolRef = poolRef
  }

  const instance: V1alpha1PulsarInstance = {
    kind: 'PulsarInstance',
    metadata: {
      name: payload.instance,
      namespace: payload.organization
    },
    spec: {
      availabilityMode: payload.availabilityMode,
      type: payload.type ? payload.type.toLowerCase() : undefined,
      poolRef: payload.poolRef
    }
  }
  if (payload.isURSAFeaturePossible && payload.engine === 'ursa') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore defined on line 31
    instance.metadata.annotations = {
      'cloud.streamnative.io/engine': 'ursa'
    }
  }

  const { data } = await api.createNamespacedPulsarInstance(payload.organization, instance)

  return data
}
