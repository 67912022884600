import type { InjectionKey, Ref } from 'vue/dist/vue.d.ts'
import * as LDClient from 'launchdarkly-js-client-sdk'
import type { App } from 'vue'
import type { LDOptions, LDContext } from 'launchdarkly-js-client-sdk'
import { type FlagRef, getLDFlag } from '@/auth/ld/getLDFlag'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { dependencies } from '../../../package.json'
import { useRbac } from '@/composables/useRbac'

// Derive the launch darkly version from the package.json, removing any ^ or ~ from the version number
// Mimics their library https://github.com/launchdarkly/vue-client-sdk/blob/main/src/index.ts#L4
const version = dependencies['launchdarkly-vue-client-sdk'].replace(/[^0-9.]/g, '')

export const ORG_LD_CONTEXT = 'ORG_LD_CONTEXT'
export const NEW_RBAC_FLAG = 'new-rbac'
export const RBAC_V2_FLAG = 'rbac-v2'
export const BYOC_BASE_ON_CLOUD_ENVIRONMENT_FLAG = 'byoc-base-on-cloud-environment'

export const ORG_LD_INIT = Symbol() as InjectionKey<
  (o?: LDPluginOptions) => [Readonly<Ref<boolean>>, LDClient.LDClient]
>
export const ORG_LD_READY = Symbol() as InjectionKey<Readonly<Ref<boolean>>>
export const ORG_LD_CLIENT = Symbol() as InjectionKey<LDClient.LDClient>
export const ORG_LD_FLAG = Symbol() as InjectionKey<
  <T>(flagKey: string, defaultFlagValue?: T | undefined) => FlagRef<T>
>

// Pulled from https://github.com/launchdarkly/vue-client-sdk/blob/1.2.1/src/index.ts#L65
// to allow us to use different keys for provide and inject for an org and a user scoped client.
export const OrgLDPlugin = {
  async install(app: App, pluginOptions: LDPluginOptions = {}) {
    const { enabled } = useRbac()
    const { rbacV2Enabled } = useRbacV2()
    const $ldReady = ref(false)
    const $ldInit = async (
      initOptions: LDPluginOptions = {}
    ): Promise<[Readonly<Ref<boolean>>, LDClient.LDClient]> => {
      const clientSideID = initOptions.clientSideID ?? pluginOptions.clientSideID
      if (!clientSideID) {
        throw new Error(`Cannot initialize LaunchDarkly without a clientSideID`)
      }
      // shared key is super important
      // without a shared key, every user is unique
      // which costs money.
      const context = initOptions.context ??
        pluginOptions.context ?? { kind: 'organization', key: 'shared', anonymous: true }
      const options = initOptions.options ?? pluginOptions.options
      const wrapperOptions = { wrapperName: 'vue-client-sdk', wrapperVersion: version }

      const $ldClient = LDClient.initialize(clientSideID, context, {
        ...wrapperOptions,
        ...options
      })
      app.provide(ORG_LD_CLIENT, $ldClient)
      app.config.globalProperties.$ldClient = $ldClient

      // TODO waitForInitialization throws an error, we likely want waitUntilReady
      //      which does not
      // await $ldClient.waitForInitialization()
      await $ldClient.waitUntilReady()

      const enableStreaming = !(
        pluginOptions.streaming === false || initOptions.streaming === false
      )
      app.provide(ORG_LD_FLAG, getLDFlag($ldReady, $ldClient, enableStreaming))
      app.provide(ORG_LD_READY, $ldReady)

      $ldReady.value = true
      // TODO to note: all `on` handlers only run when the dom loads
      // see https://developer.mozilla.org/en-US/docs/Web/Events/Event_handlers
      $ldClient.on('ready', () => ($ldReady.value = true))

      // requires streaming to be on; this resets on page load
      $ldClient.on(`change:${NEW_RBAC_FLAG}`, (val, prev) => {
        enabled.value = val
      })
      $ldClient.on(`change:${RBAC_V2_FLAG}`, (val, prev) => {
        rbacV2Enabled.value = val
      })

      return [$ldReady, $ldClient]
    }

    app.config.globalProperties.$ldReady = $ldReady
    await $ldInit(pluginOptions)
  }
}

// Redefining this to avoid a key collision the Vue's provide/inject
export type LDPluginOptions = {
  /**
   * Indicates which LaunchDarkly project to use. Must be provided here or in a call to {@link ldInit} for the SDK to work.
   */
  clientSideID?: string | undefined

  /**
   * A LaunchDarkly context object. If unspecified, an anonymous context
   * with kind: 'user' will be created and used.
   */
  context?: LDContext

  /**
   * @deprecated The `user` property will be removed in a future version,
   * please update your code to use context instead.
   */
  user?: LDContext

  /**
   * Enables or disables automatically subscribing to live updates to flags referenced using {@link useLDFlag}.
   *
   * @defaultValue `true`
   */
  streaming?: boolean

  /**
   * Defers initialization of the LaunchDarkly client until {@link ldInit} is called explicitly.
   *
   * @defaultValue `false`
   */
  deferInitialization?: boolean

  /**
   * Options to pass to the underlying javascript SDK.
   */
  options?: LDOptions | undefined
}
