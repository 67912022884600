import {
  _auth0ClientCredentialsFlow,
  _createServiceAccount,
  _createServiceAccountBinding,
  _deleteServiceAccount,
  _fetchServiceAccounts,
  _listServiceAccountBinding,
  _getServiceAccount,
  _deleteServiceAccountBinding
} from '@/api/serviceaccounts'
import { listApiKeysApi } from '@/api/apiKey'
import type {
  V1alpha1ServiceAccountList,
  V1alpha1APIKey,
  CloudV1alpha1PoolMemberReference
} from '@streamnative/cloud-api-client-typescript'
import { i18n } from '@/lang'
import type { PulsarState } from './usePulsarState'
import { groupBy } from 'lodash-es'
const { t } = i18n.global

export interface ApiKey {
  id?: string
  name?: string
  description?: string
  instanceName?: string
  serviceAccountName?: string
  created?: string
  expire?: string
  encryptedToken?: string
  token?: string
  status?: string
}

export interface ServiceAccount {
  name: string
  organization: string
  token: { jwtToken: string; expiry: number } | undefined
  createTime: string
  privateKeyData: string | undefined
  clientEmail: string
  status: string
  disabled: boolean
  isSuper: boolean
  activeApiKeysCount?: number
  apiKeys?: ApiKey[]
  bindings: string[]
}

export interface ServiceAccountConfig {
  client_email: string
  client_id: string
  client_secret: string
  issuer_url: string
  type: string
}

export interface Auth0ClientFlowPayload extends ServiceAccountConfig {
  audience: string
  grant_type: string
}

interface OrgNameTuple {
  organization: string
  name: string
}

let lastOrg: string | undefined = undefined
const serviceAccounts = ref<Array<ServiceAccount>>([])
const activeAccount = ref<null | ServiceAccount>(null)
const { clusterMap } = useCluster()
const clusterPoolMemberRefMap = computed(() => {
  const map: Record<string, Array<string>> = {}
  Object.values(clusterMap.value).forEach(clusterList => {
    clusterList.forEach(cluster => {
      const key = `${cluster.spec?.poolMemberRef?.namespace}/${cluster.spec?.poolMemberRef?.name}`
      if (!map[key]) {
        map[key] = []
      }
      map[key].push(cluster.metadata.name)
    })
  })

  return map
})
const apiKeys = ref<ApiKey[]>([])
const serviceAccountTokenCache: Record<
  string,
  { jwtToken: string; expiry: number; expireAt: number }
> = {}
export const emptyEmail = 'Empty'

const serviceAccountRoleEmails = computed<Array<string>>(() => {
  return serviceAccounts.value
    .map(account => account.clientEmail)
    .filter(email => email !== emptyEmail)
})
const availableAccounts = computed<Array<ServiceAccount>>(() => {
  return serviceAccounts.value.filter(account => account.status === 'Created')
})
const activeAccountKeyData = computed<Auth0ClientFlowPayload>(() => {
  if (activeAccount.value?.privateKeyData) {
    return JSON.parse(window.atob(activeAccount.value?.privateKeyData))
  }

  return null
})

const getServiceAccounts = async (organization?: string | undefined, apiKeysEnabled?: boolean) => {
  if (!organization) {
    organization = usePulsarState().mustOrganization()
  }
  const { instanceNames } = useInstance()
  const result = await _fetchServiceAccounts(organization)
  const bindings = await _listServiceAccountBinding(organization)

  let apiKeyGroup: Record<string, V1alpha1APIKey[]> = {}
  if (apiKeysEnabled) {
    const { items: _apiKeys } = await listApiKeysApi(organization)
    apiKeyGroup = groupBy(_apiKeys, item => item.spec?.serviceAccountName)
  }

  const _serviceAccounts: V1alpha1ServiceAccountList['items'] = result.items
  serviceAccounts.value = []
  _serviceAccounts.forEach(account => {
    const { metadata, status } = account
    const saApiKeys = apiKeysEnabled ? apiKeyGroup[metadata?.name || ''] || [] : []

    let accountStatus, disabled

    if (metadata?.deletionTimestamp) {
      accountStatus = 'Deleting'
      disabled = true
    } else if (status?.conditions?.[0].status === 'True') {
      accountStatus = 'Created'
      disabled = false
    } else {
      accountStatus = 'Creating'
      disabled = true
    }

    // This should never happen, metadata should always have name, namespace and creationTimestamp
    if (!(metadata?.name && metadata?.namespace && metadata?.creationTimestamp)) {
      throw Error(t('serviceAccount.invalidReturnedServiceAccount'))
    }

    const bindingList = bindings
      .map((b: any) => {
        if (b.spec.serviceAccountName !== account.metadata?.name) {
          return undefined
        }

        return `${b.spec.poolMemberRef.namespace}/${b.spec.poolMemberRef.name}`
      })
      .filter(b => b !== undefined) as Array<string>

    serviceAccounts.value.push({
      token: undefined,
      name: metadata?.name,
      organization: metadata?.namespace,
      createTime: metadata?.creationTimestamp,
      privateKeyData: status?.privateKeyData,
      clientEmail:
        disabled || status?.privateKeyData === undefined
          ? emptyEmail
          : JSON.parse(window.atob(status?.privateKeyData as string))['client_email'],
      status: accountStatus,
      disabled,
      isSuper:
        metadata?.annotations?.['annotations.cloud.streamnative.io/service-account-role'] ===
        'admin',
      activeApiKeysCount: apiKeysEnabled
        ? saApiKeys.filter(apiKey => {
            return (
              apiKey.spec?.instanceName &&
              instanceNames.value.includes(apiKey.spec?.instanceName) &&
              apiKey.status?.conditions?.find(c => {
                return c.type === 'Revoked' && c.status === 'False'
              }) &&
              apiKey.status?.conditions?.find(c => {
                return c.type === 'Expired' && c.status === 'False'
              })
            )
          }).length
        : undefined,
      apiKeys: apiKeysEnabled
        ? saApiKeys
            .filter(
              apiKey =>
                apiKey.spec?.instanceName && instanceNames.value.includes(apiKey.spec?.instanceName)
            )
            .map(apiKey => {
              const issued =
                apiKey.status?.conditions?.find(c => {
                  return c.type === 'Issued' && c.status === 'True'
                }) && apiKey.status.keyId
              const revoked = apiKey.status?.conditions?.find(c => {
                return c.type === 'Revoked' && c.status === 'True'
              })
              const expired = apiKey.status?.conditions?.find(c => {
                return c.type === 'Expired' && c.status === 'True'
              })
              const oldKey = apiKeys.value.find(key => key.id === apiKey.status?.keyId)
              return {
                id: apiKey.status?.keyId,
                encryptedToken: apiKey.status?.encryptedToken?.jwe,
                token: apiKey.status?.token,
                name: apiKey.metadata?.name,
                description: apiKey.spec?.description,
                instanceName: apiKey.spec?.instanceName,
                serviceAccountName: apiKey.spec?.serviceAccountName,
                issuedAt: apiKey.status?.issuedAt || apiKey.metadata?.creationTimestamp,
                expiresAt: apiKey.spec?.expirationTime ?? 'Never Expires',
                revokedAt: apiKey.status?.revokedAt,
                status: revoked
                  ? 'Revoked'
                  : expired
                  ? 'Expired'
                  : oldKey?.status === 'Revoking'
                  ? 'Revoking'
                  : issued
                  ? 'Active'
                  : 'Creating'
              }
            })
        : undefined,
      bindings: [...new Set(bindingList)].sort()
    })
  })
}

const getServiceAccountApiKeys = async (serviceAccountName: string, organization?: string) => {
  await getServiceAccounts(organization, true)
  apiKeys.value =
    serviceAccounts.value.find(saKeys => saKeys.name === serviceAccountName)?.apiKeys || []
  return apiKeys
}

const getServiceAccount = async ({ organization, name }: OrgNameTuple) => {
  const result = await _getServiceAccount(organization, name)
  const bindings = await _listServiceAccountBinding(organization)

  if (!result.status?.privateKeyData) {
    throw Error(t('serviceAccount.getServiceAccount'))
  }

  const { metadata, status } = result

  // This should never happen, metadata should always have name, namespace and creationTimestamp
  if (!(metadata?.name && metadata?.namespace && metadata?.creationTimestamp)) {
    throw Error(t('serviceAccount.invalidReturnedServiceAccount'))
  }

  const bindingList = bindings
    .map((b: any) => {
      if (b.spec.serviceAccountName !== metadata?.name) {
        return undefined
      }

      return `${b.spec.poolMemberRef.namespace}/${b.spec.poolMemberRef.name}`
    })
    .filter(b => b !== undefined) as Array<string>

  activeAccount.value = {
    token: undefined,
    name: metadata?.name,
    organization: metadata?.namespace,
    createTime: metadata?.creationTimestamp,
    privateKeyData: status.privateKeyData,
    clientEmail: JSON.parse(window.atob(status?.privateKeyData as string))['client_email'],
    status: 'Created',
    disabled: false,
    isSuper:
      metadata?.annotations?.['annotations.cloud.streamnative.io/service-account-role'] === 'admin',
    bindings: [...new Set(bindingList)].sort()
  }
}
const getServiceAccountToken = async ({ name, audience }: { name: string; audience: string }) => {
  const saTarget = serviceAccounts.value.find(sa => sa.name === name)
  if (!saTarget || !saTarget?.privateKeyData) {
    // this should never happen as we should call this function per "serviceAccounts"
    throw Error('service account not found')
  }

  const cacheKey = `${name}-${audience}`
  if (
    serviceAccountTokenCache[cacheKey] &&
    Date.now() < serviceAccountTokenCache[cacheKey].expireAt
  ) {
    saTarget.token = serviceAccountTokenCache[cacheKey]
    return serviceAccountTokenCache[cacheKey].jwtToken
  }

  const serviceAccountConfig = JSON.parse(atob(saTarget?.privateKeyData))
  const auth0ClientFlowPayload = {
    ...serviceAccountConfig,
    audience: audience,
    grant_type: 'client_credentials'
  }

  const { data } = await _auth0ClientCredentialsFlow(
    serviceAccountConfig.issuer_url.replace(),
    auth0ClientFlowPayload
  )

  const jwtToken = data.access_token as string
  const expiry = data.expires_in as number

  saTarget.token = { jwtToken, expiry }
  serviceAccountTokenCache[cacheKey] = {
    jwtToken,
    expiry,
    expireAt: expiry * 1000 + Date.now() - 60000 // expire 1 minute before actual expiriation time.
  }
  return jwtToken
}

// TODO because this creates with a 'Creating' status it is filtered in many spots,
//      this needs to be fixed
const createServiceAccount = async ({
  name,
  superAdmin
}: {
  name: string
  superAdmin: boolean
}) => {
  if (name.length <= 3) {
    throw Error(t('serviceAccount.serviceAccountNameCheck'))
  }

  const organization = usePulsarState().mustOrganization()
  await _createServiceAccount(organization, name, superAdmin)

  const newAccount: ServiceAccount = {
    name,
    organization,
    token: undefined,
    createTime: new Date().toISOString(),
    privateKeyData: '',
    clientEmail: emptyEmail,
    status: 'Creating',
    disabled: true,
    isSuper: superAdmin,
    bindings: []
  }

  serviceAccounts.value = [...serviceAccounts.value, newAccount]
}
// TODO because this only updates status it is filtered in many spots, perhaps
//      improperly
const deleteServiceAccount = async (name: string) => {
  await _deleteServiceAccount(usePulsarState().mustOrganization(), name)

  serviceAccounts.value = serviceAccounts.value.map(account => {
    if (account.name !== name) {
      return account
    } else {
      return { ...account, status: 'Deleting', disabled: true }
    }
  })
}

const saveFile = (data: string, filename: string) => {
  const blob = new Blob([data], { type: 'text/plain;charset=utf-8;' })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigatorObject: any = window.navigator
  if (navigatorObject && navigatorObject.msSaveBlob) {
    // IE 10+
    navigatorObject.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    // todo: this will always be true as HtmlAnchorElement.anything is always a string
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

const saveAccountToFile = async (sa: ServiceAccount | null | undefined) => {
  const { track } = useAnalytics()
  track('service-accounts/key-file', { name: sa?.name })

  if (!sa) {
    throw Error(`Service account is not found.`)
  }
  if (!sa.privateKeyData) {
    // if private key data is missing, then try to fetch
    const result = await _getServiceAccount(sa.organization, sa.name)
    sa.privateKeyData = result.status?.privateKeyData
    sa.clientEmail = JSON.parse(window.atob(sa.privateKeyData || ''))['client_email']
  }
  if (!sa.privateKeyData) {
    // if private key data is still missing then we should throw an error.
    throw Error(`Service account ${sa.name} is not fully initialized yet.  Please try again alter.`)
  }
  const jsonData = window.atob(sa.privateKeyData)
  const filename = sa.organization + '-' + sa.name + '.json'
  saveFile(jsonData, filename)
}

export const serviceAccountNames = computed(() => {
  return serviceAccounts.value.map(sa => sa.name)
})

export const createServiceAccountBinding = async (
  saName: string,
  pm?: CloudV1alpha1PoolMemberReference
) => {
  if (!saName) {
    throw Error('service account is not defined')
  }
  if (!pm) {
    throw Error('missing poolMemberRef')
  }

  return await _createServiceAccountBinding(saName, usePulsarState().mustOrganization(), pm)
}
export const deleteServiceAccountBinding = async (
  saName: string,
  pm?: CloudV1alpha1PoolMemberReference
) => {
  if (!saName) {
    throw Error('service account is not defined')
  }
  if (!pm) {
    throw Error('missing poolMemberRef')
  }

  await _deleteServiceAccountBinding(saName, usePulsarState().mustOrganization(), pm)
}

export const init = (initialState: PulsarState) => {
  const { isRbacUpdating, can } = rbacHelper()

  const valueChanged = async ([org, ab]: [string | undefined, boolean | undefined]) => {
    if (!org) {
      serviceAccounts.value = []
      activeAccount.value = null
      lastOrg = undefined
      return
    }
    if (ab) {
      return
    }

    if (org !== lastOrg) {
      const { canDescribeSAList } = rbacManager()
      if (canDescribeSAList()) {
        await getServiceAccounts(org)
      }
    }
    lastOrg = org
  }

  return valueChanged([initialState.organization, isRbacUpdating.value])
}

export const useServiceAccount = () => {
  return {
    serviceAccounts,
    activeAccount,
    serviceAccountRoleEmails,
    availableAccounts,
    activeAccountKeyData,
    serviceAccountNames,
    deleteServiceAccount,
    createServiceAccount,
    getServiceAccountToken,
    getServiceAccount,
    getServiceAccounts,
    getServiceAccountApiKeys,
    saveAccountToFile,
    saveFile,
    createServiceAccountBinding,
    apiKeys,
    init,
    clusterPoolMemberRefMap
  }
}
